a {
  &:focus {
    outline: none;
  }
}

a:not([href]) {
  color: inherit;
  text-decoration: none;

  &:hover {
    color: inherit;
    text-decoration: none;
  }
}

.fc {
  td {
    width: 300px;
  }
  th {
    width: 300px;
  }
}
