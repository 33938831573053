//  ================================================================================================
//  ? TIP:  It is recommended to use this file for overriding bootstrap variables.
//  ================================================================================================


//theme color
$primary: #3577d5 !default;

//label color
$dark: #232d42 !default;

//text color
$secondary: #444444 !default;

//all text color
$body-color: #444444 !default;

$info: purple !default;

$danger: red !default;

$success: #28c76f !default;

$light: #A6ABBD !default;




:root {
  --main-color: #3577d5;
  --second-color: #FF08E2;
  --nav-icon: #444444 ;

}


::-webkit-scrollbar {
  width: 5px;
  height: 8px;
}

::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background: $primary;
}

.customSelect {
  display: block !important;
  position: relative !important;
}

.customSelect .select__menu {
  z-index: 50;
  color: $secondary !important;
  /* FIXME: This is the select options class which needs to be shown at top always */
}

.select__control .select-img span {
  color: $secondary !important;
}

.rdt_TableHeadRow {
  background-color: $primary !important;
  text-transform: capitalize;
}

.react-dataTable
  .rdt_TableHead
  .rdt_TableHeadRow
  .rdt_TableCol
  .rdt_TableCol_Sortable {
  color: #fff !important;
}

.form-control:disabled {
  background-color: rgba(12, 50, 189, 0.15);
  color: $primary;
}

/*booking page full calendar style */

.fc-button-group button {
  color: $primary !important;
  border-color: $primary !important;
  background: #fff !important;
  box-shadow: none !important;
  font-weight: 700 !important;
  text-transform: capitalize !important;
}

.fc-button-group button:focus {
  background: $primary !important;
  color: #fff !important;
}

.fc-searchDoctor-button {
  color: $primary !important;
  border-color: $primary !important;
  background: #fff !important;
  box-shadow: none !important;
}

.fc .fc-button-primary:not(:disabled).fc-button-active, .fc .fc-button-primary:not(:disabled):active{
   background: $primary !important;
  color: #fff !important;
}

.fc-searchDoctor-button:focus {
  background: $primary !important;
  color: #fff !important;
}

.fc-addNote-button {
  border-color: $primary !important;
  background: $primary !important;
  box-shadow: none !important;
}

.fc-addNote-button:focus {
  background: $primary !important;
  color: #fff !important;
}

.fc-today-button {
  /* border: 2px solid #0abab5 !important; */
  border-color: $primary !important;
  background: #fff !important;
  box-shadow: none !important;
  width: 150px !important;
  margin: 0 10px !important;
}

.fc-today-button:focus {
  background: $primary !important;
  color: #fff !important;
}

.fc-resourceTimelineDay-button {
  width: 100px !important;
}

.fc-resourceTimelineWeek-button {
  width: 100px !important;
}

.fc-resourceTimelineMonth-button {
  width: 100px !important;
}

.fc-event-title {
  width: 100% !important;
  white-space: normal !important;
  word-wrap: break-word !important;
}

.fc-event {
  border-radius: 8px;
}

.fc-customButton-button {
  background-color: $primary !important;
  padding: 7px 10px !important;
  color: white !important;
  border: none !important;
  cursor: pointer !important;
  border-radius: 5px !important;
  outline: none !important;
}

.fc-view-harness {
  height: 700px !important;
  overflow: auto !important;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  padding: 16px !important;
  background: #fff;
}

/* .fc-datagrid-cell-frame {
     padding: 10px 0px;
     height: auto;
     border-top: 1px solid #0000001f;
  } */

.fc .fc-col-header-cell-cushion {
  padding: 10px !important;
  text-align: center;
  width: 100%;
}

.fc .fc-timegrid-slot-label {
  padding: 15px !important;
}

.fc-direction-ltr .fc-daygrid-event.fc-event-end,
.fc-direction-rtl .fc-daygrid-event.fc-event-start {
  text-wrap: wrap;
  color: white;
  margin-top: 10px;
  min-width: 250px !important;
  min-height: 60px !important;
}

.fc-timegrid-event {
  text-wrap: wrap;
  color: white;
  min-width: 220px !important;
  min-height: 60px !important;
  margin-top: 5px;
}





// .fc-scroller-liquid-absolute {
//     overflow: auto !important;
// }



// .fc .fc-view-harness {
//   width: calc(100dvw - 316px) !important;
//   overflow: hidden !important;

// }

// .fc-dayGridMonth-view { 
//     width: 100%!important; 
//     overflow: auto!important;
// }


// .fc-scroller-harness {
//    overflow:visible !important;
//   .fc-scroller {
//     overflow: visible !important;
//     width: 100% !important;
//   }
//   .fc-col-header {
//     width: 100% !important;
//   }
// }

// .fc .fc-scroller-harness-liquid {
//   height: 100% !important;
//   overflow-y: auto !important;
//   width: 100% !important;
// }

// //  ** Remove Overflow hidden And Make it visible to show all events  :
// .fc .fc-scroller-liquid-absolute {
//   overflow: visible !important;
//   width: 100%  !important;
// }


// .fc-daygrid-body {
//      width: 100% !important;
// }

// .fc-scrollgrid-sync-table{
//     width: 100% !important; 
// }






//  ** Here All New CSS Code : 
.fc-scroller-harness {
     width: 100% !important; 
     overflow:visible !important;
}

.fc-scroller-harness .fc-scroller {
    overflow: visible!important;
    width:100% !important;
}


// Date Color
.selectDate {
  width: 100%;
  height: 30px;
  padding: 18px 9px !important;
  border-radius: 4px;
  border: 1px solid #A6ABBD !important;
  text-align: center;
}

.selectDate:focus {
  border: 1px solid $dark;
  outline: none;
}


.selectDate::placeholder {
  color : $dark !important;
}


button.fc-button.fc-button-primary.fc-next-button:not(:disabled):focus,button.fc-button.fc-button-primary.fc-prev-button:not(:disabled):focus{
  background: white !important;
  color: $primary !important;
}


.main-menu.menu-light .navigation > li.open:not(.menu-item-closing) > a,  .main-menu.menu-light .navigation > li.sidebar-group-active > a {
  background: $primary !important; 
  color: white !important; 
} 
.main-menu.menu-light .navigation > li.open:not(.menu-item-closing) > a svg,  .main-menu.menu-light .navigation > li.sidebar-group-active > a  svg{
  /* fill: white !important;  */
  color: white !important
} 


.main-menu.menu-light .navigation > li ul .active{
  background: none !important;
  color: $primary !important;
}

/* .vertical-layout.vertical-menu-modern .main-menu .navigation li.has-sub a:after{
  background: url(./assets/icons/arrowWithoutRotate.svg) no-repeat !important; 
}
 */


/* .vertical-layout.vertical-menu-modern.menu-expanded .main-menu .navigation li.has-sub > a:after {
 background: url(./assets/icons/arrowIcon.svg) no-repeat !important;  
} */

.selectDate1 {
  width: 100%;
  height: 30px;
  padding: 25px 12px !important;
  border-radius: 4px;
  border: 1px solid #A6ABBD !important;
  text-align: start;
  font-size: large !important;
}

.selectDate1:focus {
  border: 1px solid $dark;
  outline: none;
}


.selectDate1::placeholder {
  color : $dark !important;
}
